import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
// import { getDatabase, ref, onValue} from "firebase/database";
import { database } from "../../../Firebase";
import { TableHeader } from "../../Table/DataTable";
import dateFormat from "dateformat";
import ReactPaginate from "react-paginate";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import axios from "axios";
import { URL } from "../../../Redux/common/url";

function UserList() {
  const [userSList, setuserSList] = useState([]);
  const [comments, setComments] = useState([]);

  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);

  useEffect(() => {
    const starCountRef = database.ref("users");

    console.log("test");

    starCountRef.on("value", (snapchat) => {
      let words = snapchat.val();
      var arr = [];
      for (let word in words) {
        arr.push(words[word]);
      }
      //   setComments(arr);
    });

    var config = {
      method: "post",
      url: URL.API_BASE_URL + "/apiPublic/getUserAdmin",
      headers: {
        "Content-Type": "application/json",
      },
      // data: data,
    };

    axios(config)
      .then(function (response) {
        setComments(response.data?.data);
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // console.log(userSList);

  const hendleitemsPerPage = (e) => {
    setitemsPerPage(parseInt(e.target.value));
  };

  //   var data = JSON.stringify({
  //     mobile_no: "7014585810",
  //     password: "123456",
  //   });

  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const headers = [
    // { name: "No", field: "id", sortable: true },
    { name: "Name", field: "name", sortable: false },
    // { name: "Number", field: "number", sortable: false },
    { name: "Email", field: "email", sortable: false },
    { name: "Number", field: "mobile_no", sortable: false },
    { name: "Date", field: "date", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    // if (search) {
    //     computedComments = computedComments.filter(
    //         comment =>
    //             comment.name.toLowerCase().includes(search.toLowerCase()) ||
    //             comment.email.toLowerCase().includes(search.toLowerCase())
    //     );
    // }

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * itemsPerPage,
      (currentPage - 1) * itemsPerPage + itemsPerPage
    );
  }, [comments, currentPage, search, sorting, itemsPerPage]);

  const handlePageClick = (event) => {
    const newOffset = event.selected % (comments.length / 2);
    setCurrentPage(newOffset + 1);
    setItemOffset(newOffset * itemsPerPage);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setPageCount(Math.ceil(comments.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, comments]);
  return (
    <div>
      <Header />
      <Sidebar />
      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to={"/"}> Deshbosrd</Link>/{" "}
                <a className="pageAcctive">Images</a>
              </li>
            </ol>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-title">Images</h4>

                  <div>
                    {/* <h3>lokesh</h3> */}
                    {/* <div class="custom-control custom-switch">
                                            <input type="checkbox" class="custom-control-input" id="customSwitches" role="switch" onChange={(e) => {
                                                hendleProtectProfile(e.target.checked);
                                            }} checked={protectProfile} />
                                            <label class="custom-control-label" for="customSwitches">slider {protectProfile == true ? "active" : "inactive"}</label>
                                        </div> */}
                  </div>

                  <div className="addPlus">
                    {/* <a
                                            href="#category"
                                            className="login_button newQue"
                                            data-toggle="modal"
                                        >
                                            <i className="fa fa-plus-circle" aria-hidden="true"></i>
                                        </a> */}
                  </div>
                </div>
                <div className="card-body">
                  <div
                    id="example3_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      className="dataTables_length category"
                      id="example3_length"
                    >
                      <label>
                        Show{" "}
                        <div className="dropdown category">
                          <select
                            name="example3_length"
                            aria-controls="example3"
                            className=""
                            tabIndex="-98"
                            onChange={(e) => {
                              hendleitemsPerPage(e);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value={"25"}>25</option>
                            <option value={"50"}>50</option>
                            <option value={"100"}>100</option>
                          </select>
                        </div>{" "}
                        entries
                      </label>
                    </div>
                    {/* <div
                                            id="example3_filter"
                                            className="dataTables_filter category"
                                        >


                                            <Search
                                                onSearch={value => {
                                                    setSearch(value);
                                                    setCurrentPage(1);
                                                }}
                                            />

                                        </div> */}

                    <div className="row w-100">
                      <div className="col mb-3 col-12 text-center">
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6 d-flex flex-row-reverse"></div>
                        </div>

                        <table className="table table-striped">
                          <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                              setSorting({ field, order })
                            }
                          />
                          <tbody>
                            {commentsData.map((comment, i) => {
                              const createDate = dateFormat(
                                comment?.createdAt,
                                "dd-mm-yyyy"
                              );

                              console.log(comment);

                              return (
                                <tr key={i}>
                                  {/* <td className="GallaryImg" onClick={(e) => { imageviews(i) }} ><img src={comment?.image} alt="" /></td> */}

                                  <td>{comment?.name}</td>
                                  <td>{comment?.email}</td>
                                  <td>{comment?.mobile_no}</td>
                                  <td>{createDate}</td>
                                  <td>
                                    <div className="d-flex">
                                      {/* <a
                                                                                href="#views" data-toggle="modal" onClick={(e) => { viewHendle(comment?.id) }}
                                                                                className="btn btn-primary shadow btn-xs sharp mr-1"
                                                                            >
                                                                                <i className="fa fa-eye" aria-hidden="true"></i>
                                                                            </a> */}

                                      {/* <a
                                                                                href="#delete" data-toggle="modal"
                                                                                className="btn btn-danger shadow btn-xs sharp" onClick={(e) => { setdeleteCategory(comment?.id) }}
                                                                            >
                                                                                <i className="fa fa-trash"></i>
                                                                            </a> */}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      className="dataTables_info category"
                      id="example3_info"
                      role="status"
                      aria-live="polite"
                    >
                      Showing {itemOffset} to{" "}
                      {comments?.length < itemOffset + itemsPerPage
                        ? comments?.length
                        : itemOffset + itemsPerPage}{" "}
                      of{" "}
                      {/* {getParentCategory && getParentCategory?.length} */}{" "}
                      {comments?.length} entries
                    </div>
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel="next >"
                      className="dataTables_paginate paging_simple_numbers category"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel="< previous"
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserList;
