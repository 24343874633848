export const URL = {
  // dev
  // API_BASE_URL:"http://192.168.0.8:3000",

  // creato
  // API_BASE_URL:"http://localhost:5500",
  API_BASE_URL: "https://www.backend.premiumcricketline.com",
  // API_BASE_URL: "http://54.204.54.38:3000",
  adminLogin: "/dhadmin/adminLogin",
  createParentCategory: "/dhadmin/createParentCategory",
  createSubCategory: "/dhadmin/createSubCategory",
  createSubjects: "/dhadmin/createSubjects",
  getParentCategories: "api/getParentCategories",
  getCourseSubCategory: "api/getCourseSubCategory",
  getAllSubjects: "api/getAllSubjects",
  deleteParentCategory: "dhadmin/deleteParentCategory",
  deleteSubCategory: "dhadmin/deleteSubCategory",
  deleteSubject: "dhadmin/deleteSubject",
  getCourseSubCategoryByParentid: "api/getCourseSubCategoryByParentid",
  getAllUsers: "dhadmin/getAllUsers",
  getUsersDetails: "dhadmin/getUsersDetails",

  getLatestAssignments: "dhadmin/getLatestAssignments",
  getLatestQuestions: "dhadmin/getLatestQuestions",
  getAssignmentDetailsAdmin: "dhadmin/getAssignmentDetailsAdmin",
  getQuestionDetailsAdmin: "dhadmin/getQuestionDetailsAdmin",
};
