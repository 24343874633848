import { SHOW_TOGGEL } from "../constant";


const initialState = {
  loading: false,
  showToggel: {},

};

export default (state = initialState, action) => {


  switch (action.type) {
 
    case SHOW_TOGGEL:
      return { ...state, showToggel: action.payload };
     
    default:
      return state;
  }
};