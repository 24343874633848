import { SHOW_TOGGEL } from "../constant";

const getUsersDetailsAction = (data) => {
  return { type: SHOW_TOGGEL, payload: data };
};



export const ShowtoggelAction = (data) => {
  return async (dispatch) => {
      dispatch(getUsersDetailsAction(data));   
  };
};