import React, { useState, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { ShoetoggelAction } from '../../../Redux/common/action';
import { useSelector, useDispatch } from "react-redux";
import MenuOutSideClick from './outsideClick';

function Header() {

	const navigate = useNavigate();
	// let history = useHistory();
	const dispatch = useDispatch();




	const anchorRef1 = useRef(null);
	const anchorRef2 = useRef(null);
	const [anchor1, setAnchor1] = useState(false);
	const [anchor2, setAnchor2] = useState(false);
	MenuOutSideClick(anchorRef1, setAnchor1);
	MenuOutSideClick(anchorRef2, setAnchor2);


	const [categoryValue, setcategoryValue] = useState(true);

	const logout = async () => {
		await localStorage.clear();
		navigate("/login");
	};


	const getAssignment = useSelector((state) => state?.showtoggel?.showToggel ? state?.showtoggel?.showToggel : {})



	const createCategory = (e) => {




		if (categoryValue == true) {
			setcategoryValue(true)
			dispatch(ShoetoggelAction.ShowtoggelAction("true"));
			return () => { };
		} else {
			setcategoryValue(true)
			dispatch(ShoetoggelAction.ShowtoggelAction("true"));
			return () => { };
		}



	};




	return (
		<div>



			<div>

				<div className="nav-header">

					<Link to={"/"} className="brand-logo">
						Premium cricket line
					</Link>



					<div className="nav-control">
						<div className="hamburger" ref={anchorRef1} onClick={(e) => { createCategory(e) }}>
							<span className="line"></span><span className="line"></span><span className="line"></span>
						</div>

						{anchor1 && (
							<div
								className="searchuniversitiesandcourses"
								style={{ padding: "0px" }}
							>
								<ul>

									<li >

										<Link to={"/"}> Dashboard</Link>

									</li>

									<li>

										<Link to={"/poll"}> Poll</Link>

									</li>
									<li>

										<Link to={"/prediction"}> Prediction</Link>

									</li>
									<li>

										<Link to={"/slider"}> Slider</Link>

									</li>
									<li>

										<Link to={"/users"}> User</Link>

									</li>
									<li>

										<Link to={"/notification"}> Notification</Link>

									</li>
									{/* <li>
                                  
								<Link to={"/contects"}> Contects</Link>
                                 
                                </li>
                                <li>
                                  
								<Link to={"/contect-details"}> Contect Details</Link>
                                 
                                </li> */}


								</ul>
							</div>
						)}
					</div>
				</div>




				<div className="chatbox">
					<div className="chatbox-close"></div>
					<div className="custom-tab-1">
						<ul className="nav nav-tabs">
							<li className="nav-item">
								<a className="nav-link" data-toggle="tab" href="#notes">Notes</a>
							</li>
							<li className="nav-item">
								<a className="nav-link" data-toggle="tab" href="#alerts">Alerts</a>
							</li>
							<li className="nav-item">
								<a className="nav-link active" data-toggle="tab" href="#chat">Chat</a>
							</li>
						</ul>
						<div className="tab-content">
							<div className="tab-pane fade active show" id="chat" role="tabpanel">
								<div className="card mb-sm-3 mb-md-0 contacts_card dz-chat-user-box">
									<div className="card-header chat-list-header text-center">
										<a href="#"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" /><rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" /></g></svg></a>
										<div>
											<h6 className="mb-1">Chat List</h6>
											<p className="mb-0">Show All</p>
										</div>
										<a href="#"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24" /><circle fill="#000000" cx="5" cy="12" r="2" /><circle fill="#000000" cx="12" cy="12" r="2" /><circle fill="#000000" cx="19" cy="12" r="2" /></g></svg></a>
									</div>
									<div className="card-body contacts_body p-0 dz-scroll  " id="DZ_W_Contacts_Body">
										<ul className="contacts">
											<li className="name-first-letter">A</li>
											<li className="active dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/1.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon"></span>
													</div>
													<div className="user_info">
														<span>Archie Parker</span>
														<p>Kalid is online</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/2.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>Alfie Mason</span>
														<p>Taherah left 7 mins ago</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/3.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon"></span>
													</div>
													<div className="user_info">
														<span>AharlieKane</span>
														<p>Sami is online</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/4.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>Athan Jacoby</span>
														<p>Nargis left 30 mins ago</p>
													</div>
												</div>
											</li>
											<li className="name-first-letter">B</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/5.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>Bashid Samim</span>
														<p>Rashid left 50 mins ago</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/1.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon"></span>
													</div>
													<div className="user_info">
														<span>Breddie Ronan</span>
														<p>Kalid is online</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/2.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>Ceorge Carson</span>
														<p>Taherah left 7 mins ago</p>
													</div>
												</div>
											</li>
											<li className="name-first-letter">D</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/3.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon"></span>
													</div>
													<div className="user_info">
														<span>Darry Parker</span>
														<p>Sami is online</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/4.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>Denry Hunter</span>
														<p>Nargis left 30 mins ago</p>
													</div>
												</div>
											</li>
											<li className="name-first-letter">J</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/5.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>Jack Ronan</span>
														<p>Rashid left 50 mins ago</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/1.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon"></span>
													</div>
													<div className="user_info">
														<span>Jacob Tucker</span>
														<p>Kalid is online</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/2.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>James Logan</span>
														<p>Taherah left 7 mins ago</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/3.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon"></span>
													</div>
													<div className="user_info">
														<span>Joshua Weston</span>
														<p>Sami is online</p>
													</div>
												</div>
											</li>
											<li className="name-first-letter">O</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/4.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>Oliver Acker</span>
														<p>Nargis left 30 mins ago</p>
													</div>
												</div>
											</li>
											<li className="dz-chat-user">
												<div className="d-flex bd-highlight">
													<div className="img_cont">
														<img src="images/avatar/5.jpg" className="rounded-circle user_img" alt="" />
														<span className="online_icon offline"></span>
													</div>
													<div className="user_info">
														<span>Oscar Weston</span>
														<p>Rashid left 50 mins ago</p>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
								<div className="card chat dz-chat-history-box d-none">
									<div className="card-header chat-list-header text-center">
										<a href="#" className="dz-chat-history-back">
											<svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><polygon points="0 0 24 0 24 24 0 24" /><rect fill="#000000" opacity="0.3" transform="translate(15.000000, 12.000000) scale(-1, 1) rotate(-90.000000) translate(-15.000000, -12.000000) " x="14" y="7" width="2" height="10" rx="1" /><path d="M3.7071045,15.7071045 C3.3165802,16.0976288 2.68341522,16.0976288 2.29289093,15.7071045 C1.90236664,15.3165802 1.90236664,14.6834152 2.29289093,14.2928909 L8.29289093,8.29289093 C8.67146987,7.914312 9.28105631,7.90106637 9.67572234,8.26284357 L15.6757223,13.7628436 C16.0828413,14.136036 16.1103443,14.7686034 15.7371519,15.1757223 C15.3639594,15.5828413 14.7313921,15.6103443 14.3242731,15.2371519 L9.03007346,10.3841355 L3.7071045,15.7071045 Z" fill="#000000" fillRule="nonzero" transform="translate(9.000001, 11.999997) scale(-1, -1) rotate(90.000000) translate(-9.000001, -11.999997) " /></g></svg>
										</a>
										<div>
											<h6 className="mb-1">Chat with Khelesh</h6>
											<p className="mb-0 text-success">Online</p>
										</div>
										<div className="dropdown">
											<a href="#" data-toggle="dropdown" ><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24" /><circle fill="#000000" cx="5" cy="12" r="2" /><circle fill="#000000" cx="12" cy="12" r="2" /><circle fill="#000000" cx="19" cy="12" r="2" /></g></svg></a>
											<ul className="dropdown-menu dropdown-menu-right">
												<li className="dropdown-item"><i className="fa fa-user-circle text-primary mr-2"></i> View profile</li>
												<li className="dropdown-item"><i className="fa fa-users text-primary mr-2"></i> Add to close friends</li>
												<li className="dropdown-item"><i className="fa fa-plus text-primary mr-2"></i> Add to group</li>
												<li className="dropdown-item"><i className="fa fa-ban text-primary mr-2"></i> Block</li>
											</ul>
										</div>
									</div>
									<div className="card-body msg_card_body dz-scroll" id="DZ_W_Contacts_Body3">
										<div className="d-flex justify-content-start mb-4">
											<div className="img_cont_msg">
												<img src="images/avatar/1.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
											<div className="msg_cotainer">
												Hi, how are you samim?
												<span className="msg_time">8:40 AM, Today</span>
											</div>
										</div>
										<div className="d-flex justify-content-end mb-4">
											<div className="msg_cotainer_send">
												Hi Khalid i am good tnx how about you?
												<span className="msg_time_send">8:55 AM, Today</span>
											</div>
											<div className="img_cont_msg">
												<img src="images/avatar/2.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
										</div>
										<div className="d-flex justify-content-start mb-4">
											<div className="img_cont_msg">
												<img src="images/avatar/1.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
											<div className="msg_cotainer">
												I am good too, thank you for your chat template
												<span className="msg_time">9:00 AM, Today</span>
											</div>
										</div>
										<div className="d-flex justify-content-end mb-4">
											<div className="msg_cotainer_send">
												You are welcome
												<span className="msg_time_send">9:05 AM, Today</span>
											</div>
											<div className="img_cont_msg">
												<img src="images/avatar/2.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
										</div>
										<div className="d-flex justify-content-start mb-4">
											<div className="img_cont_msg">
												<img src="images/avatar/1.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
											<div className="msg_cotainer">
												I am looking for your next templates
												<span className="msg_time">9:07 AM, Today</span>
											</div>
										</div>
										<div className="d-flex justify-content-end mb-4">
											<div className="msg_cotainer_send">
												Ok, thank you have a good day
												<span className="msg_time_send">9:10 AM, Today</span>
											</div>
											<div className="img_cont_msg">
												<img src="images/avatar/2.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
										</div>
										<div className="d-flex justify-content-start mb-4">
											<div className="img_cont_msg">
												<img src="images/avatar/1.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
											<div className="msg_cotainer">
												Bye, see you
												<span className="msg_time">9:12 AM, Today</span>
											</div>
										</div>
										<div className="d-flex justify-content-start mb-4">
											<div className="img_cont_msg">
												<img src="images/avatar/1.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
											<div className="msg_cotainer">
												Hi, how are you samim?
												<span className="msg_time">8:40 AM, Today</span>
											</div>
										</div>
										<div className="d-flex justify-content-end mb-4">
											<div className="msg_cotainer_send">
												Hi Khalid i am good tnx how about you?
												<span className="msg_time_send">8:55 AM, Today</span>
											</div>
											<div className="img_cont_msg">
												<img src="images/avatar/2.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
										</div>
										<div className="d-flex justify-content-start mb-4">
											<div className="img_cont_msg">
												<img src="images/avatar/1.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
											<div className="msg_cotainer">
												I am good too, thank you for your chat template
												<span className="msg_time">9:00 AM, Today</span>
											</div>
										</div>
										<div className="d-flex justify-content-end mb-4">
											<div className="msg_cotainer_send">
												You are welcome
												<span className="msg_time_send">9:05 AM, Today</span>
											</div>
											<div className="img_cont_msg">
												<img src="images/avatar/2.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
										</div>
										<div className="d-flex justify-content-start mb-4">
											<div className="img_cont_msg">
												<img src="images/avatar/1.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
											<div className="msg_cotainer">
												I am looking for your next templates
												<span className="msg_time">9:07 AM, Today</span>
											</div>
										</div>
										<div className="d-flex justify-content-end mb-4">
											<div className="msg_cotainer_send">
												Ok, thank you have a good day
												<span className="msg_time_send">9:10 AM, Today</span>
											</div>
											<div className="img_cont_msg">
												<img src="images/avatar/2.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
										</div>
										<div className="d-flex justify-content-start mb-4">
											<div className="img_cont_msg">
												<img src="images/avatar/1.jpg" className="rounded-circle user_img_msg" alt="" />
											</div>
											<div className="msg_cotainer">
												Bye, see you
												<span className="msg_time">9:12 AM, Today</span>
											</div>
										</div>
									</div>
									<div className="card-footer type_msg">
										<div className="input-group">
											<textarea className="form-control" placeholder="Type your message..."></textarea>
											<div className="input-group-append">
												<button type="button" className="btn btn-primary"><i className="fa fa-location-arrow"></i></button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="tab-pane fade" id="alerts" role="tabpanel">
								<div className="card mb-sm-3 mb-md-0 contacts_card">
									<div className="card-header chat-list-header text-center">
										<a href="#"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24" /><circle fill="#000000" cx="5" cy="12" r="2" /><circle fill="#000000" cx="12" cy="12" r="2" /><circle fill="#000000" cx="19" cy="12" r="2" /></g></svg></a>
										<div>
											<h6 className="mb-1">Notications</h6>
											<p className="mb-0">Show All</p>
										</div>
										<a href="#"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24" /><path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" /><path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" /></g></svg></a>
									</div>
									<div className="card-body contacts_body p-0 dz-scroll" id="DZ_W_Contacts_Body1">
										<ul className="contacts">
											<li className="name-first-letter">SEVER STATUS</li>
											<li className="active">
												<div className="d-flex bd-highlight">
													<div className="img_cont primary">KK</div>
													<div className="user_info">
														<span>David Nester Birthday</span>
														<p className="text-primary">Today</p>
													</div>
												</div>
											</li>
											<li className="name-first-letter">SOCIAL</li>
											<li>
												<div className="d-flex bd-highlight">
													<div className="img_cont success">RU<i className="icon fa-birthday-cake"></i></div>
													<div className="user_info">
														<span>Perfection Simplified</span>
														<p>Jame Smith commented on your status</p>
													</div>
												</div>
											</li>
											<li className="name-first-letter">SEVER STATUS</li>
											<li>
												<div className="d-flex bd-highlight">
													<div className="img_cont primary">AU<i className="icon fa fa-user-plus"></i></div>
													<div className="user_info">
														<span>AharlieKane</span>
														<p>Sami is online</p>
													</div>
												</div>
											</li>
											<li>
												<div className="d-flex bd-highlight">
													<div className="img_cont info">MO<i className="icon fa fa-user-plus"></i></div>
													<div className="user_info">
														<span>Athan Jacoby</span>
														<p>Nargis left 30 mins ago</p>
													</div>
												</div>
											</li>
										</ul>
									</div>
									<div className="card-footer"></div>
								</div>
							</div>
							<div className="tab-pane fade" id="notes">
								<div className="card mb-sm-3 mb-md-0 note_card">
									<div className="card-header chat-list-header text-center">
										<a href="#"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" /><rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1" /></g></svg></a>
										<div>
											<h6 className="mb-1">Notes</h6>
											<p className="mb-0">Add New Nots</p>
										</div>
										<a href="#"><svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="18px" height="18px" viewBox="0 0 24 24" version="1.1"><g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd"><rect x="0" y="0" width="24" height="24" /><path d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z" fill="#000000" fillRule="nonzero" opacity="0.3" /><path d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z" fill="#000000" fillRule="nonzero" /></g></svg></a>
									</div>
									<div className="card-body contacts_body p-0 dz-scroll" id="DZ_W_Contacts_Body2">
										<ul className="contacts">
											<li className="active">
												<div className="d-flex bd-highlight">
													<div className="user_info">
														<span>New order placed..</span>
														<p>10 Aug 2020</p>
													</div>
													<div className="ml-auto">
														<a href="#" className="btn btn-primary btn-xs sharp mr-1"><i className="fa fa-pencil"></i></a>
														<a href="#" className="btn btn-danger btn-xs sharp"><i className="fa fa-trash"></i></a>
													</div>
												</div>
											</li>
											<li>
												<div className="d-flex bd-highlight">
													<div className="user_info">
														<span>Youtube, a video-sharing website..</span>
														<p>10 Aug 2020</p>
													</div>
													<div className="ml-auto">
														<a href="#" className="btn btn-primary btn-xs sharp mr-1"><i className="fa fa-pencil"></i></a>
														<a href="#" className="btn btn-danger btn-xs sharp"><i className="fa fa-trash"></i></a>
													</div>
												</div>
											</li>
											<li>
												<div className="d-flex bd-highlight">
													<div className="user_info">
														<span>john just buy your product..</span>
														<p>10 Aug 2020</p>
													</div>
													<div className="ml-auto">
														<a href="#" className="btn btn-primary btn-xs sharp mr-1"><i className="fa fa-pencil"></i></a>
														<a href="#" className="btn btn-danger btn-xs sharp"><i className="fa fa-trash"></i></a>
													</div>
												</div>
											</li>
											<li>
												<div className="d-flex bd-highlight">
													<div className="user_info">
														<span>Athan Jacoby</span>
														<p>10 Aug 2020</p>
													</div>
													<div className="ml-auto">
														<a href="#" className="btn btn-primary btn-xs sharp mr-1"><i className="fa fa-pencil"></i></a>
														<a href="#" className="btn btn-danger btn-xs sharp"><i className="fa fa-trash"></i></a>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>




				<div className="header">
					<div className="header-content">
						<nav className="navbar navbar-expand">
							<div className="collapse navbar-collapse justify-content-between">
								<div className="header-left">
									<div className="dashboard_bar">
										{/* Dashboard */}
									</div>
								</div>

								<ul className="navbar-nav header-right">














									{/*	<li className="nav-item">
						<div className="input-group search-area d-lg-inline-flex d-none">
							<input type="text" className="form-control" placeholder="Search something here..." />
							<div className="input-group-append">
								<span className="input-group-text"><i className="flaticon-381-search-2"></i></span>
							</div>
						</div>
					</li>
					<li className="nav-item dropdown notification_dropdown">
						<a className="nav-link bell bell-link" href="#">
							<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M22.5678 26.5202C22.8079 26.5202 23.0447 26.6115 23.2249 26.7856C24.3769 27.8979 26.0572 28.2683 27.551 27.8047C26.5897 25.802 26.4564 23.5075 27.2014 21.383C28.126 18.7398 28.3577 16.0905 27.3055 13.4334C26.381 11.0992 24.5971 9.15994 22.3395 8.05408C22.4784 8.79455 22.5484 9.54903 22.5484 10.3115C22.5484 13.5478 21.304 16.5916 19.0444 18.8823C16.7846 21.1733 13.7553 22.4615 10.5147 22.5097C9.91267 22.5191 9.31331 22.4837 8.72073 22.4056C10.5017 25.5274 13.8606 27.5606 17.5516 27.6153C19.1663 27.6403 20.7166 27.302 22.1604 26.6125C22.2904 26.5503 22.4296 26.5202 22.5678 26.5202Z" fill="#3E4954"/>
								<path d="M10.541 0.00236249C4.79223 -0.111786 0.0134501 4.53885 -0.000411333 10.2863C-0.00380737 11.6906 0.270302 13.052 0.814361 14.3331C0.822262 14.3517 0.829608 14.3706 0.836262 14.3897C1.58124 16.5142 1.4481 18.8086 0.486678 20.8114C1.98059 21.2748 3.66073 20.9046 4.81275 19.7922C5.09656 19.518 5.5212 19.449 5.8773 19.6192C7.3209 20.3087 8.87143 20.648 10.486 20.6221C16.1898 20.5374 20.6576 16.0085 20.6576 10.3117C20.6576 4.73921 16.1193 0.114501 10.541 0.00236249ZM4.81898 11.8517C3.99305 11.8517 3.32348 11.1832 3.32348 10.3587C3.32348 9.53414 3.99305 8.86568 4.81898 8.86568C5.64492 8.86568 6.31449 9.53414 6.31449 10.3587C6.31442 11.1832 5.64492 11.8517 4.81898 11.8517ZM10.3286 11.8517C9.50268 11.8517 8.8331 11.1832 8.8331 10.3587C8.8331 9.53414 9.50268 8.86568 10.3286 8.86568C11.1545 8.86568 11.8241 9.53414 11.8241 10.3587C11.8241 11.1832 11.1545 11.8517 10.3286 11.8517ZM15.8383 11.8517C15.0124 11.8517 14.3428 11.1832 14.3428 10.3587C14.3428 9.53414 15.0124 8.86568 15.8383 8.86568C16.6642 8.86568 17.3338 9.53414 17.3338 10.3587C17.3338 11.1832 16.6642 11.8517 15.8383 11.8517Z" fill="#3E4954"/>
							</svg>
							<span className="badge light text-white bg-primary rounded-circle">18</span>
						</a>
					</li>
					<li className="nav-item dropdown notification_dropdown">
						<a className="nav-link  ai-icon" href="#" role="button" data-toggle="dropdown">
							<svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M9.45251 25.6682C10.0606 27.0357 11.4091 28 13.0006 28C14.5922 28 15.9407 27.0357 16.5488 25.6682C15.4266 25.7231 14.2596 25.76 13.0006 25.76C11.7418 25.76 10.5748 25.7231 9.45251 25.6682Z" fill="#3E4954"/>
								<path d="M25.3531 19.74C23.8769 17.8785 21.3995 14.2195 21.3995 10.64C21.3995 7.09073 19.1192 3.89758 15.7995 2.72382C15.7592 1.21406 14.5183 0 13.0006 0C11.4819 0 10.2421 1.21406 10.2017 2.72382C6.88095 3.89758 4.60064 7.09073 4.60064 10.64C4.60064 14.2207 2.12434 17.8785 0.647062 19.74C0.154273 20.3616 0.00191325 21.1825 0.240515 21.9363C0.473484 22.6721 1.05361 23.2422 1.79282 23.4595C3.08755 23.8415 5.20991 24.2715 8.44676 24.491C9.84785 24.5851 11.3543 24.64 13.0007 24.64C14.646 24.64 16.1524 24.5851 17.5535 24.491C20.7914 24.2715 22.9127 23.8415 24.2085 23.4595C24.9477 23.2422 25.5268 22.6722 25.7597 21.9363C25.9983 21.1825 25.8448 20.3616 25.3531 19.74Z" fill="#3E4954"/>
							</svg>
							<span className="badge light text-white bg-primary rounded-circle">52</span>
						</a>
						<div className="dropdown-menu dropdown-menu-right">
							<div id="DZ_W_Notification1" className="widget-media dz-scroll p-3 height380">
								<ul className="timeline">
									 <li>
										<div className="timeline-panel">
											<div className="media mr-2">
												<img alt="image" width="50" src="images/avatar/1.jpg" />
											</div>
											<div className="media-body">
												<h6 className="mb-1">Dr sultads Send you Photo</h6>
												<small className="d-block">29 July 2020 - 02:26 PM</small>
											</div>
										</div>
									</li>
									<li>
										<div className="timeline-panel">
											<div className="media mr-2 media-info">
												KG
											</div>
											<div className="media-body">
												<h6 className="mb-1">Resport created successfully</h6>
												<small className="d-block">29 July 2020 - 02:26 PM</small>
											</div>
										</div>
									</li>
									<li>
										<div className="timeline-panel">
											<div className="media mr-2 media-success">
												<i className="fa fa-home"></i>
											</div>
											<div className="media-body">
												<h6 className="mb-1">Reminder : Treatment Time!</h6>
												<small className="d-block">29 July 2020 - 02:26 PM</small>
											</div>
										</div>
									</li>
									 <li>
										<div className="timeline-panel">
											<div className="media mr-2">
												<img alt="image" width="50" src="images/avatar/1.jpg" />
											</div>
											<div className="media-body">
												<h6 className="mb-1">Dr sultads Send you Photo</h6>
												<small className="d-block">29 July 2020 - 02:26 PM</small>
											</div>
										</div>
									</li>
									<li>
										<div className="timeline-panel">
											<div className="media mr-2 media-danger">
												KG
											</div>
											<div className="media-body">
												<h6 className="mb-1">Resport created successfully</h6>
												<small className="d-block">29 July 2020 - 02:26 PM</small>
											</div>
										</div>
									</li>
									<li>
										<div className="timeline-panel">
											<div className="media mr-2 media-primary">
												<i className="fa fa-home"></i>
											</div>
											<div className="media-body">
												<h6 className="mb-1">Reminder : Treatment Time!</h6>
												<small className="d-block">29 July 2020 - 02:26 PM</small>
											</div>
										</div>
									</li>
								</ul>
							</div>
							<a className="all-notification" href="#">See all notifications <i className="ti-arrow-right"></i></a>
						</div>
					</li> */}
									<li className="nav-item dropdown header-profile">
										<a className="nav-link" href="#" role="button" data-toggle="dropdown">
											{/* <img src="images/profile/17.jpg" width="20" alt=""/> */}
											<div className="header-info">
												<span className="text-black">Oda Dink</span>
												<p className="fs-12 mb-0">Super Admin</p>
											</div>
										</a>
										<div className="dropdown-menu dropdown-menu-right">
											{/* <a href="app-profile.html" className="dropdown-item ai-icon">
								<svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" className="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
								<span className="ml-2">Profile </span>
							</a>
							<a href="email-inbox.html" className="dropdown-item ai-icon">
								<svg id="icon-inbox" xmlns="http://www.w3.org/2000/svg" className="text-success" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>
								<span className="ml-2">Inbox </span>
							</a> */}
											<a onClick={logout} className="dropdown-item ai-icon">
												<svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" className="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
												<span className="ml-2">Logout </span>
											</a>
										</div>
									</li>
								</ul>
							</div>
						</nav>
					</div>
				</div>
			</div>






		</div>
	)
}

export default Header
