import { combineReducers } from "redux";





import showtoggelReducer from "./Show/reducer";




export default combineReducers({
    showtoggel: showtoggelReducer,
   


});