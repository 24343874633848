import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Sidebar() {
  const id = useLocation();
  const [showHide, setshowHide] = useState("");
  const [showHidetrue, setshowHidetrue] = useState(false);
  const navigate = useNavigate();

  const logout = async () => {
    await localStorage.clear();
    navigate("/");
  };

  const { pathname } = id;

  const splitLocation = pathname.split("/");

  const show = (e) => {
    if (showHide == e) {
      setshowHide(e);
    } else {
      setshowHide(e);
    }
  };

  useEffect(() => {
    if (splitLocation[1] === "") {
      setshowHide("dashboard");
    } else if (
      splitLocation[1] ===
      "gallery" /* || splitLocation[1] === "sub-catagory" ||splitLocation[1] === "subject" */
    ) {
      setshowHide("gallery");
    } else if (splitLocation[1] === "flavors") {
      setshowHide("flavors");
    } else if (splitLocation[1] === "contect-details") {
      setshowHide("contect-details");
    } else if (splitLocation[1] === "contects") {
      setshowHide("contects");
    } else if (splitLocation[1] === "questions") {
      setshowHide("question");
    }
  }, [id]);

  return (
    <div>
      <div className="deznav">
        <div className="deznav-scroll ps ">
          <ul className="metismenu" id="menu">
            <li className={showHide == "dashboard" ? "mm-active" : ""}>
              <a
                className=""
                onClick={(e) => {
                  show("dashboard");
                }}
                aria-expanded="false"
              >
                {/* <i className="flaticon-381-networking"></i> */}
                {/* <span className="nav-text">Dashboard</span> */}
                <Link
                  to={"/"}
                  className={splitLocation[1] === "" ? "mm-active" : ""}
                >
                  {" "}
                  Dashboard
                </Link>
              </a>
              {/* <ul aria-expanded="false"  className={showHide == "dashboard"? "":'mm-collapse'}>
                            <li ><Link to={"/"} className={splitLocation[1]=== "" ? "mm-active":""}>Dashboard</Link></li>
						
						</ul> */}
            </li>
            <li className={showHide == "slider" ? "mm-active" : ""}>
              <a
                className=""
                onClick={(e) => {
                  show("slider");
                }}
                aria-expanded="false"
              >
                {/* <i className="flaticon-381-networking"></i> */}
                {/* <span className="nav-text">Gallery</span> */}
                <Link
                  to={"/slider"}
                  className={splitLocation[1] === "slider" ? "mm-active" : ""}
                >
                  Slider
                </Link>
              </a>

              <ul
                aria-expanded="false"
                className={showHide == "subscribe" ? "" : "mm-collapse"}
              >
                <li></li>
              </ul>
            </li>

            <li className={showHide == "poll" ? "mm-active" : ""}>
              <a
                onClick={(e) => {
                  show("poll");
                }}
                aria-expanded="false"
              >
                <li>
                  <Link
                    to={"/poll"}
                    className={splitLocation[1] === "poll" ? "mm-active" : ""}
                  >
                    Poll
                  </Link>
                </li>
              </a>
            </li>

            <li className={showHide == "prediction" ? "mm-active" : ""}>
              <a
                onClick={(e) => {
                  show("prediction");
                }}
                aria-expanded="false"
              >
                <li>
                  <Link
                    to={"/prediction"}
                    className={
                      splitLocation[1] === "prediction" ? "mm-active" : ""
                    }
                  >
                    {" "}
                    Prediction
                  </Link>
                </li>
              </a>
            </li>

            <li className={showHide == "users" ? "mm-active" : ""}>
              <a
                className=""
                onClick={(e) => {
                  show("users");
                }}
                aria-expanded="false"
              >
                <li>
                  <Link
                    to={"/users"}
                    className={splitLocation[1] === "users" ? "mm-active" : ""}
                  >
                    {" "}
                    Users
                  </Link>
                </li>
              </a>
            </li>
            <li className={showHide == "notification" ? "mm-active" : ""}>
              <a
                className=""
                onClick={(e) => {
                  show("notification");
                }}
                aria-expanded="false"
              >
                <li>
                  <Link
                    to={"/notification"}
                    className={
                      splitLocation[1] === "notification" ? "mm-active" : ""
                    }
                  >
                    {" "}
                    Notification
                  </Link>
                </li>
              </a>
            </li>

            {/* <li className={showHide == "assignment"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("assignment")}} aria-expanded="false">
							<i className="flaticon-381-networking"></i>
							<span className="nav-text">Assignments</span>
						</a>

                        <ul aria-expanded="false"  className={showHide == "assignment"? "":'mm-collapse'}>
                            <li ><Link to={"/assignments"} className={splitLocation[1]=== "assignments" ? "mm-active":""}>Assignments</Link></li>
						
                          
							
						</ul>
                    </li> */}

            {/* <li className={showHide == "question"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("question")}} aria-expanded="false">
							<i className="flaticon-381-networking"></i>
							<span className="nav-text">Question</span>
						</a>

                        <ul aria-expanded="false"  className={showHide == "question"? "":'mm-collapse'}>
                            <li ><Link to={"/questions"} className={splitLocation[1]=== "questions" ? "mm-active":""}>Questions</Link></li>
						
                           
							
						</ul>
                    </li> */}
            {/* <li  className={showHide == "apps"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("apps")}} aria-expanded="false">
							<i className="flaticon-381-television"></i>
							<span className="nav-text">Apps</span>
						</a>
                        <ul aria-expanded="false" className={showHide == "apps"? "":'mm-collapse'}>
                            <li><a href="app-profile.html">Profile</a></li>
                            <li><a className="has-arrow" href="" aria-expanded="false">Email</a>
                                <ul aria-expanded="false">
                                    <li><a href="email-compose.html">Compose</a></li>
                                    <li><a href="email-inbox.html">Inbox</a></li>
                                    <li><a href="email-read.html">Read</a></li>
                                </ul>
                            </li>
                            <li ><a href="app-calender.html">Calendar</a></li>
							<li><a className="has-arrow" href="" aria-expanded="false">Shop</a>
                                <ul aria-expanded="false" className={showHide == "calendar"? "":'mm-collapse'}>
                                    <li><a href="ecom-product-grid.html">Product Grid</a></li>
									<li><a href="ecom-product-list.html">Product List</a></li>
									<li><a href="ecom-product-detail.html">Product Details</a></li>
									<li><a href="ecom-product-order.html">Order</a></li>
									<li><a href="ecom-checkout.html">Checkout</a></li>
									<li><a href="ecom-invoice.html">Invoice</a></li>
									<li><a href="ecom-customers.html">Customers</a></li>
                                </ul>
                            </li>
                        </ul>
                    </li> */}
            {/* <li className={showHide == "charts"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("charts")}} aria-expanded="false">
							<i className="flaticon-381-controls-3"></i>
							<span className="nav-text">Charts</span>
						</a>
                        <ul aria-expanded="false" className={showHide == "charts"? "":'mm-collapse'}>
                            <li><a href="chart-flot.html">Flot</a></li>
                            <li><a href="chart-morris.html">Morris</a></li>
                            <li><a href="chart-chartjs.html">Chartjs</a></li>
                            <li><a href="chart-chartist.html">Chartist</a></li>
                            <li><a href="chart-sparkline.html">Sparkline</a></li>
                            <li><a href="chart-peity.html">Peity</a></li>
                        </ul>
                    </li>
                    <li className={showHide == "bootstrap"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("bootstrap")}} aria-expanded="false">
							<i className="flaticon-381-internet"></i>
							<span className="nav-text">Bootstrap</span>
						</a>
                        <ul aria-expanded="false" className={showHide == "bootstrap"? "":'mm-collapse'}>
                            <li><a href="ui-accordion.html">Accordion</a></li>
                            <li><a href="ui-alert.html">Alert</a></li>
                            <li><a href="ui-badge.html">Badge</a></li>
                            <li><a href="ui-button.html">Button</a></li>
                            <li><a href="ui-modal.html">Modal</a></li>
                            <li><a href="ui-button-group.html">Button Group</a></li>
                            <li><a href="ui-list-group.html">List Group</a></li>
                            <li><a href="ui-media-object.html">Media Object</a></li>
                            <li><a href="ui-card.html">Cards</a></li>
                            <li><a href="ui-carousel.html">Carousel</a></li>
                            <li><a href="ui-dropdown.html">Dropdown</a></li>
                            <li><a href="ui-popover.html">Popover</a></li>
                            <li><a href="ui-progressbar.html">Progressbar</a></li>
                            <li><a href="ui-tab.html">Tab</a></li>
                            <li><a href="ui-typography.html">Typography</a></li>
                            <li><a href="ui-pagination.html">Pagination</a></li>
                            <li><a href="ui-grid.html">Grid</a></li>

                        </ul>
                    </li> */}
            {/* <li className={showHide == "plugins"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("plugins")}} aria-expanded="false">
							<i className="flaticon-381-heart"></i>
							<span className="nav-text">Plugins</span>
						</a>
                        <ul aria-expanded="false" className={showHide == "plugins"? "":'mm-collapse'}>
                            <li><a href="uc-select2.html">Select 2</a></li>
                            <li><a href="uc-nestable.html">Nestedable</a></li>
                            <li><a href="uc-noui-slider.html">Noui Slider</a></li>
                            <li><a href="uc-sweetalert.html">Sweet Alert</a></li>
                            <li><a href="uc-toastr.html">Toastr</a></li>
                            <li><a href="map-jqvmap.html">Jqv Map</a></li>
                        </ul>
                    </li> */}
            {/* <li className={showHide == "widget"? "mm-active":''}><a onClick={(e)=>{show("widget")}} className="ai-icon" aria-expanded="false">
							<i className="flaticon-381-settings-2"></i>
							<span className="nav-text">Widget</span>
						</a>
					</li> */}
            {/* <li className={showHide == "forms"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("forms")}} aria-expanded="false">
							<i className="flaticon-381-notepad"></i>
							<span className="nav-text">Forms</span>
						</a>
                        <ul aria-expanded="false" className={showHide == "forms"? "":'mm-collapse'}>
                            <li><a href="form-element.html">Form Elements</a></li>
                            <li><a href="form-wizard.html">Wizard</a></li>
                            <li><a href="form-editor-summernote.html">Summernote</a></li>
                            <li><a href="form-pickers.html">Pickers</a></li>
                            <li><a href="form-validation-jquery.html">Jquery Validate</a></li>
                        </ul>
                    </li> */}
            {/* <li className={showHide == "table"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("table")}} aria-expanded="false">
							<i className="flaticon-381-network"></i>
							<span className="nav-text">Table</span>
						</a>
                        <ul aria-expanded="false" className={showHide == "table"? "":'mm-collapse'}>
                            <li><a href="table-bootstrap-basic.html">Bootstrap</a></li>
                            <li><a href="table-datatable-basic.html">Datatable</a></li>
                        </ul>
                    </li> */}
            {/* <li className={showHide == "pages"? "mm-active":''}><a className="has-arrow ai-icon" onClick={(e)=>{show("pages")}} aria-expanded="false">
							<i className="flaticon-381-layer-1"></i>
							<span className="nav-text">Pages</span>
						</a>
                        <ul aria-expanded="false" className={showHide == "pages"? "":'mm-collapse'}>
                            <li><a href="page-register.html">Register</a></li>
                            <li><a href="page-login.html">Login</a></li>
                            <li><a className="has-arrow" href="" aria-expanded="false">Error</a>
                                <ul aria-expanded="false" className="mm-collapse">
                                    <li><a href="page-error-400.html">Error 400</a></li>
                                    <li><a href="page-error-403.html">Error 403</a></li>
                                    <li><a href="page-error-404.html">Error 404</a></li>
                                    <li><a href="page-error-500.html">Error 500</a></li>
                                    <li><a href="page-error-503.html">Error 503</a></li>
                                </ul>
                            </li>
                            <li><a href="page-lock-screen.html">Lock Screen</a></li>
                        </ul>
                    </li> */}
          </ul>

          {/* <div className="copyright">
					<p><strong>Jobie Dashboard</strong> ©All Rights Reserved</p>
					<p>by DexignZone</p>
				</div> */}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
