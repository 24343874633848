import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { database } from "../../../Firebase";
import { URL } from "../../../Redux/common/url";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import qs from "qs";

// var qs = require('qs');

function Notification() {
  const [userSList, setuserSList] = useState([]);
  const [first, setfirst] = useState({});

  useEffect(() => {
    const starCountRef = database.ref("users");

    starCountRef.on("value", (snapchat) => {
      let words = snapchat.val();
      var arr = [];
      for (let word in words) {
        arr.push(words[word]);
      }
      setuserSList(arr);
    });
  }, []);

  const fcmtokan =
    userSList &&
    userSList?.map((data) => {
      return data.token;
    });
  const fcmtokan1 =
    fcmtokan &&
    fcmtokan?.filter((data) => {
      return data !== undefined;
    });

  // var data = JSON.stringify({
  //     "order_amount": req.body.data?.order_amount.toString(),
  //     "order_currency": "INR",
  //     "customer_details": {
  //         "customer_id": req.body.data?.customer_id.toString(),
  //         "customer_phone": req.body.data?.customer_phone.toString(),
  //         "customer_name": req.body.data?.customer_name.toString()
  //     }
  // });

  const hendledata = (e) => {
    const { name, value } = e.target;
    setfirst({ ...first, [name]: value });
  };

  first.ids = { "ids[]": fcmtokan1 };

  // console.log(first);

  const hendleSubmit = (e) => {
    // data.append('ids[]', fcmtokan1);

    // fcmtokan1?.forEach(file => {

    //   // console.log(file);
    //     data.append(`ids`, file);
    //   });

    // for (let index = 0; index < fcmtokan1.length; index++) {
    //     const element = fcmtokan1[index];

    //     data.append(`ids[${index}]`, element);
    // }

    // var config = {
    //     method: 'post',
    //     url: 'https://devparadigms.com/premiumcricketleague/index.php',
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Accept': 'application/json',
    //     },
    //     data: data
    // };

    // axios(config).then(function (response) {
    //     toast.success("success " + response?.data?.success)
    //     // console.log(response);
    // })
    //     .catch(function (error) {

    //     });
    //     var axios = require('axios');

    var data = qs.stringify({
      title: first?.title,
      message: first?.body,
    });

    // var data = new FormData();

    // data.append("title", first?.title);
    // data.append("message", first?.body);

    var config = {
      method: "post",
      url: URL.API_BASE_URL + "/apiPublic/sendNotification",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data?.status == true) {
          setfirst({ title: "", body: "" });

          toast.success(response?.data?.message);
          //   console.log(JSON.stringify(response.data));
        } else {
          console.log(response?.data);
          toast.error(response?.data?.msg?.message);
        }
      })
      .catch(function (error) {
        // console.log(error);
        // console.log(error?.data?.msg);

        toast.error(error?.msg);
      });
  };

  return (
    <div>
      <Header />
      <Sidebar />

      <div className="content-body">
        <div className="container-fluid">
          <div className="page-titles">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">
                <Link to={"/"}> Deshbosrd</Link>/{" "}
                <a className="pageAcctive">Notification</a>
              </li>
            </ol>
          </div>

          <div>
            <div className="notibox">
              <label>Title</label>
              <input
                placeholder="enter title"
                className=' className="form-control input-default'
                name="title"
                value={first?.title}
                onChange={(e) => {
                  hendledata(e);
                }}
              />
              <label>Message</label>
              <input
                placeholder="enter message"
                className=' className="form-control input-default '
                name="body"
                value={first?.body}
                onChange={(e) => {
                  hendledata(e);
                }}
              />

              <div class="submitBtn">
                <button
                  type="button"
                  className="btn btn-primary mt-3"
                  onClick={hendleSubmit}
                >
                  send notification
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
