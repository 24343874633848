import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import Dashboard from '../Component/Dashboard/Dashboard';

import Error404 from '../Component/Layout/Error/Error404';

import Prediction from '../Component/Layout/Flavors/Prediction';
import Images from '../Component/Layout/Images/Images';
import Index from '../Component/Layout/Index';
import Notification from '../Component/Layout/Notification/Notification';
import Poll from '../Component/Layout/Poll/Poll';
import UserList from '../Component/Layout/UserList/UserList';
import Login from '../Component/Login/Login';
import AuthAdmin from './AuthAdmin';





function AppRoutes() {
    return (
        <div>
            {/* <Switch>
                <ProtectedRoute exact path="/" component={Dashboard} />

                <ProtectedRoute exact path="/prediction" component={Prediction} />

                <ProtectedRoute exact path="/gallery" component={Images} />


                <LoginProtectRoutes exact path="/login" component={Login} />

                <Route exact path="" component={Error404} />
            </Switch> */}


            <BrowserRouter>
                {/* <Index/> */}
                <Routes>
                    <Route path="/login" element={<Login />} />
                    {/* <Route path="/" element={<Dashboard />} /> */}
                    <Route path="/" element={<AuthAdmin cmp={Dashboard} />} />
                    <Route path="/notification" element={<AuthAdmin cmp={Notification} />} />
                    <Route path="/prediction" element={<AuthAdmin cmp={Prediction} />} />
                    <Route path="/slider" element={<AuthAdmin cmp={Images} />} />
                    <Route path="/poll" element={<AuthAdmin cmp={Poll} />} />
                    <Route path="/users" element={<AuthAdmin cmp={UserList} />} />
                    {/* <Route path="/gallery" element={<Images />} /> */}
                    <Route path="" element={<Error404 />} />

                </Routes>
            </BrowserRouter>

        </div>
    )
}



export default AppRoutes
