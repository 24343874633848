import logo from './logo.svg';
import './App.css';
import Index from './Component/Layout/Index';
import AppRoutes from './Routes/AppRoutes';

import { useSelector } from "react-redux";
import { useState } from 'react';
import { getTokens } from './Firebase';

function App() {
  const getAssignment = useSelector((state) => state?.showtoggel?.showToggel ? state?.showtoggel?.showToggel: {})


  const [isTokenFound, setTokenFound] = useState(false);
  // getTokens(setTokenFound)



  // {isTokenFound && 
  //   Notification permission enabled 👍🏻 
  //  }
  //  {!isTokenFound && 
  //   Need notification permission ❗️ 
  //  }
  return (
    <div class={getAssignment == "false" ? "show menu-toggle" : "show "} >
      {/* <Router> */}
        {/* <Index /> */}
        {/* { commenreducer &&
<LoadingScreen/>} */}
        <AppRoutes />
      {/* </Router> */}
    </div>
  );
}

export default App;
